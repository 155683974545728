.App {
  text-align: center;
  background-color: #e4e4e44a;
  min-height: 100vh;
  font-family: "Courier New", Courier, monospace;
}

.App-header {
  background-color: #1b53c4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: "Courier New", Courier, monospace;
}

.MainImageSize {
  width: 50;
}

.MainContainer {
  min-height: 100vh;
  text-align: center;
}

.App-link {
  color: #61dafb;
}
footer {
  bottom: 0;
  text-align: center;
  margin-top: auto;
  border-top: solid 1px #a3a3a3;
}

a {
  color: #565b63;
  font-size: large;
  font-family: "Edu AU VIC WA NT Hand", cursive;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

a:hover {
  color: #be8b8b;
}

.active {
  color: #9c1717;
}

.col {
  padding: 0 55px;
}

.navbar-expand {
  border-bottom: solid 1px #9b2626;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #565b63;
  font-family: "Edu AU VIC WA NT Hand", cursive;
  font-weight: 700;
  font-style: normal;
}

strong {
  font-family: "Edu AU VIC WA NT Hand";
  font-weight: 1200;
  font-style: bold;
}

@media screen and (max-width: 900px) {
  a {
    font-size: 70%;
    padding: 0;
  }

  h1,
  h2 {
    font-size: 100%;
  }
  p {
    font-size: 55%;
  }
}
